import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import useSiteMeta from '../hooks/useSiteMeta';

interface ISeoProps {
    /**
     * The page title. Automatically prefixed with "Trades Training BC - "
     */
    title?: string;

    /**
     * The meta description for the page. Defaults to the siteMetadata description in gatsby-config.
     */
    description?: string;

    /**
     * Adds lang attribute to the html document. Defaults to "en"
     */
    lang?: string;

    /**
     * An array of any additional meta to add to the head.
     */
    meta?: any[];

    children?: any;
}

function Seo({ description, lang, meta, title, children }: ISeoProps) {
    const siteMetadata = useSiteMeta();

    const metaDescription = description || siteMetadata.description;
    const defaultTitle = siteMetadata?.title;

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang,
                }}
                title={title || defaultTitle}
                titleTemplate={defaultTitle && title ? `${defaultTitle} - %s` : null}
                meta={[
                    {
                        name: `description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:title`,
                        content: title,
                    },
                    {
                        property: `og:description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:description`,
                        content: metaDescription,
                    },
                ].concat(meta)}
            >
                {children}
            </Helmet>
        </>
    );
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default Seo;
