import { graphql, useStaticQuery } from "gatsby"

const useSiteMeta = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  return siteMetadata
}

export default useSiteMeta
